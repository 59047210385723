<template>
  <div>
    <v-img 
      :src="src" width="172px" 
      @touchstart="active=true" 
      @touchend.stop.prevent ="active=false;openSearchTributo()"   
      @click.stop.prevent="openSearchTributo()"
      class="mx-auto"> 
    </v-img>
    <p class="homeSubtitle text-center mt-4 colorCustom2--text font-weight-bold">{{ tributo.nombre}}</p>
  </div>
</template>

<script>
  export default {    
    props: {
      tributo: {
        type: Object,
        required: true,
      },
    },
    data () { return {
      active: false,
    }},
    computed : {
      src : function () {
        return !this.active ? this.tributo.src_img : this.tributo.src_img_click
      },
    },
    mounted() { 
      this.active = false;
    },
    methods : {
      openSearchTributo : function () {    
          switch (this.tributo.codigo) {
            case 1:
              this.$router.push('/SEARCH/ALCVP_SV_CPC/') 
              break;
            case 2:
              this.$router.push('/SEARCH/MOTOVEHICULOS/') 
              break;     
            case 3:
              this.$router.push('/SEARCH/AUTOMOTORES/') 
              break;   
            case 4:
              this.$router.push('/SEARCH/COMERCIOS/') 
              break;   
            case 5:
              this.$router.push('/SEARCH/PILETA/') 
              break;   
            case 6:
              this.$router.push('/SEARCH/CEMENTERIO/') 
              break;          
            default:
              break;
          }     
        return false;    
      },
    }
  }
</script>
<style scoped>
.homeSubtitle {
  font-size:21px !important;
}
</style>
