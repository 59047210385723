import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {      
            primary: '#2962FF',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
            colorCustom1: '#2962FF',
            colorCustom2: '#132C7A',
            colorCustom3: '#E9E9E9'        
          },
        },
      },
});
// 612D7A