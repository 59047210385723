<script>
  export default {
    methods: {
        $agregarPropiedadCheck: function (registros) {
          registros.cuotasVencidas.forEach(function (item) {
              item.check = false    
          });
          registros.cuotasAVencer.forEach(function (item) {
              item.check = false       
          });              
          return registros
        },   
    },
  }
</script>    