import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import axiosMod from './modules/axios'
import apiService from './modules/apiService'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    axiosMod,
    apiService,    
  },
  state: {
    mensajes: {'noCuenta': 'El Nº de cuenta ingresado no se encuentra registrado.',
               'noDni': 'El DNI ingresado no se encuentra registrado.',
               'noDominio': 'El dominio ingresado no se encuentra registrado.',
               'error': 'Ocurrío un error, vuelva a intentarlo mas tarde.',
              },
    token: localStorage.getItem('access_token') || null,
    userId: localStorage.getItem('userId') || null,
    headers : {
      'Content-Type': 'application/json',
      'service_key' :  Math.random() * (1111111111 - 999999999999999) + 1111111111,
    },
    fecha: null,
    loader: 0,
    disableBtn: false,
    background: 'background',
    layoutComplete: {default: ['` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                                '{tab} q w e r t y u i o p [ ] \\',
                                '{lock} a s d f g h j k l ñ ; \' {enter}',
                                '{shift} z x c v b n m , . / {shift}',
                                '.com @ {space}' ]},

    layoutNumeric: { default: ['1 2 3', '4 5 6', '7 8 9', " 0 {bksp}"] },

    layoutAlfaNumeric: {default: ['1 2 3 4 5 6 7 8 9 0',
        'Q W E R T Y U I O P',
        'A S D F G H J K L Ñ',
        'Z X C V B N M {bksp}']},   

    buttonThemeNumeric: [
      {
        class: "customSize1",
        buttons: "{bksp}"
      },
    ],    
    buttonThemeAlfaNumeric: [
      {
        class: "customSize2",
        buttons: "{bksp}"
      },
    ]       
  },
  getters: {
    get_input(state) {
      return state.input
    },
    getUserId(state) {
      return state.userId
    },
    getFecha(state) {
      return state.fecha
    },   
    getlayoutComplete(state) {
      return state.layoutComplete
    },  
    getbackground(state) {
      return state.background
    },     
    getLayoutNumeric(state) {
      return state.layoutNumeric
    }, 
    getLayoutAlfaNumeric(state) {
      return state.layoutAlfaNumeric
    },
    getButtonThemeNumeric(state) {
      return state.buttonThemeNumeric
    },
    getButtonThemeAlfaNumeric(state) {
      return state.buttonThemeAlfaNumeric
    },
    getMensajes(state) {
      return state.mensajes
    }
  },
  mutations: {
    retrieveToken (state, token) {
      state.token = token
    },
    loaderOn (state) {
      state.loader = state.loader + 1
    },
    loaderOff (state) {
      state.loader = state.loader - 1
    },    
    setDisableBtn (state, value) {
      state.disableBtn = value
    },
    setFecha(state, fecha) {
      state.fecha = fecha
    },   
    setbackground(state, background) {
      state.background = background
    },
    setUserId(state, userId) {
      state.userId = userId
    }
  },
  actions: {
    retrieveToken (context, credentials) {
      context.commit('loaderOn')
      context.commit('setDisableBtn',true)
      localStorage.setItem('service_key', this.state.headers.service_key)
      return new Promise((resolve, reject) => {
        axios.post('/SAT_WS/rest/autenticacion', {
          cuit: credentials.username,
          clave: credentials.password,
        })
        .then(response => {
            console.log('response:', response)
            const token = response.headers.auth_token
            localStorage.setItem('access_token', token)
            context.commit('retrieveToken', token)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          }).finally( () => { 
            context.commit('loaderOff')
            context.commit('setDisableBtn',false)
          })
      })
    },   
    loadFecha (context) {
      return new Promise((resolve, reject) => {
        axios.get('/SAT_WS/rest/consulta/fecha')
        .then(response => {
            context.commit('setFecha', response.data.fecha)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })     
    } 
  }
})
