<template>
<div>
  <v-container class="landscape">
    <v-row justify="center" style="margin-top:220px">
      <v-img src="/img/T_paso1.png" max-width="1080" > </v-img>
    </v-row>      
  </v-container>
  <v-container class="landscape">
      <v-row align="center" style="margin-top:75px"> 
        <v-col sm="12">
          <v-row>
            <v-col sm="4">
              <cuadro-tributo :tributo="tributos[0]"/>
            </v-col>
            <v-col sm="4">
              <cuadro-tributo :tributo="tributos[1]"/>
            </v-col>
            <v-col sm="4">
              <cuadro-tributo :tributo="tributos[2]"/>
            </v-col>
          </v-row>
        </v-col>        
        <v-col sm="12" class="mt-14">
          <v-row>
            <v-col sm="4" class="offset-sm-2">
              <cuadro-tributo :tributo="tributos[3]"/><!--  -->
            </v-col>
            <v-col sm="4">
              <cuadro-tributo :tributo="tributos[4]"/>
            </v-col>            
          </v-row>
        </v-col>
      </v-row>    
  </v-container>
  <v-container class="portrait">
      <v-row align="center" style="margin-top:75px"> 
        <v-col sm="12">
          <v-row>
            <v-col sm="4" xl="2" offset-xl="2">
              <cuadro-tributo :tributo="tributos[0]"/>
            </v-col>
            <v-col sm="4" xl="2" offset-xl="1">
              <cuadro-tributo :tributo="tributos[1]"/>
            </v-col>
            <v-col sm="4" xl="2" offset-xl="1">
              <cuadro-tributo :tributo="tributos[2]"/>
            </v-col>
            <v-col sm="4" class="offset-sm-2" xl="2" offset-xl="4">
              <cuadro-tributo :tributo="tributos[3]"/><!--  -->
            </v-col>
            <v-col sm="4" xl="2" offset-xl="1">
              <cuadro-tributo :tributo="tributos[4]"/>
            </v-col>            
          </v-row>
        </v-col>
      </v-row>    
  </v-container>
</div>
</template>

<script>
  import cuadroTributo from '../components/cuadroTributo'

  export default {
    name: 'Home',

    components: {
      cuadroTributo,
    },
    mounted () {
    },
    data () {
      return {
        tributos: [
          { codigo : 1 , nombre: 'ALUMBRADO, LIMPIEZA Y CONSERVACIÓN DE LA VÍA PÚBLICA', src_img: 'img/01abl1.svg', src_img_click: 'img/01abl2.svg'},
          { codigo : 2 , nombre: 'MOTOVEHÍCULOS', src_img: 'img/01moto1.svg', src_img_click: 'img/01moto2.svg'},
          { codigo : 3 , nombre: 'AUTOMOTORES', src_img: 'img/01auto1.svg', src_img_click: 'img/01auto2.svg'},
          { codigo : 4 , nombre: 'COMERCIOS / INDUSTRIAS ', src_img: 'img/01rs1.svg', src_img_click: 'img/01rs2.svg'},
          { codigo : 5 , nombre: 'PILETA', src_img: 'img/01pile1.svg', src_img_click: 'img/01pile2.svg'}, 
          { codigo : 6 , nombre: 'Cementerio', src_img: 'img/01cem1.svg', src_img_click: 'img/01cem2.svg'},
        ]
      }
    }
  }
</script>
