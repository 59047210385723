import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/pages/Login.vue'),  
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },  
  {
    path: '/SEARCH/ALCVP_SV_CPC',
    name: 'ALCVP Y SV, Y CPC',
    component: () => import('../views/buscarAbl.vue')
  },    
  {
    path: '/SEARCH/MOTOVEHICULOS',
    name: 'MOTOVEHICULO',
    component: () => import(/* webpackChunkName: "buscarMotovehiculo" */ '../views/buscarMotovehiculo.vue')
  },     
  {
    path: '/SEARCH/AUTOMOTORES',
    name: 'AUTOMOTORES',
    component: () => import('../views/buscarAutomotor.vue')
  },    
  {
    path: '/SEARCH/COMERCIOS',
    name: 'COMERCIOS',
    component: () => import('../views/buscarComercio.vue')
  },    
  {
    path: '/SEARCH/PILETA',
    name: 'PILETA',
    component: () => import('../views/buscarPileta.vue')
  },    
  {
    path: '/SEARCH/CEMENTERIO',
    name: 'CEMENTERIO',
    component: () => import('../views/buscarCementerio.vue')
  },        
  {
    path: '/DETALLE/:tributo/:cuenta',
    name: 'cuotasGeneral',
    component: () => import('../views/cuotasGeneral.vue'),
    props: function (route) {
      return {
        tributo: String(route.params.tributo) || 0,
        cuenta: String(route.params.cuenta) || 0, 
      }
    },    
  }, 
  {
    path: '/DETALLE/:tributo/:cuenta',
    name: 'cuotasPileta',
    component: () => import('../views/cuotasPileta.vue'),
    props: function (route) {
      return {
        tributo: String(route.params.tributo) || 0,
        cuenta: String(route.params.cuenta) || 0, 
      }
    },    
  },   
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },      
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
