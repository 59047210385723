<template>
  <v-app class="background">
     <v-main>
        <router-view/>
      </v-main>
        <loading  :active="loading"
                  :is-full-page="true"
                  loader="dots" 
                  color="red" />  
    <IdleDialog  v-if="isIdle && !isHome"></IdleDialog>
  </v-app>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import IdleDialog from './components/idleDialog.vue'
export default {
  name: 'App',
  components: {
      Loading,
      IdleDialog,
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },    
    loading: function () {
      return this.$store.state.loader > 0 ? true : false;
    },
    isHome: function () {
      return (this.$route.name === 'Home' || this.$route.name === 'Login') ? true : false
    }
  },
   beforeCreate: function () {     
    window.addEventListener("contextmenu", function(e) {
      e.preventDefault();
    })         
  },   
  
  watch: {
    $route: function() {
      this.$store.state.idleVue.isIdle = false
    }
  }
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background-size: cover;
 }

.background {  
  height: 100% !important;
  background: url( '/img/background.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}


/* html {
  overflow: hidden !important;
   scrollbar-width: none; 
  -ms-overflow-style: none;
  touch-action:none;
} */

/*  html::-webkit-scrollbar {
  width: 0;
  height: 0;  
}    */

@media (orientation: landscape) {
  .landscape { display: none ; }
}

@media (orientation: portrait) {
  .portrait { display: none }
}
</style>