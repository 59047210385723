<script>
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  export default {
    methods: {
      $showError () {
        Swal.fire({
        title: 'ERROR',
        text: 'Vuelva a intentarlo o prueba más tarde',
        icon: 'error',
        confirmButtonText: 'Ok'
        })  
      },
      $showNoExisteCuenta () {
        Swal.fire({
        title: 'El Nº de cuenta ingresado no se encuentra registrado.',
        text: '',
        icon: 'error',
        confirmButtonText: 'Ok'
        })         
      },
      $showNoExisteDominio () {
        Swal.fire({
        title: 'El dominio ingresado no se encuentra registrado.',
        text: '',
        icon: 'error',
        confirmButtonText: 'Ok'
        })         
      },      
      $showNoExisteDni () {
        Swal.fire({
        title: 'El DNI ingresado no se encuentra registrado.',
        text: '',
        icon: 'error',
        confirmButtonText: 'Ok'
        })         
      },        
      $showNoSeleccion () {
        Swal.fire({
        title: 'Seleccioná al menos una cuota.',
        text: '',
        icon: 'error',
        confirmButtonText: 'Ok'
        })         
      },
      $showEsMora () {
        Swal.fire({
          icon: 'info',
          text: 'Los periodos marcados corresponden a una deuda en proceso de ser reclamada judicialmente. Deberá ser abonada en su totalidad',
          title: '',
          confirmButtonText: 'Ok',
          timer: 10000,          
        })   
      },
      $swalClose () {
        Swal.close()
      }
    },
  }
</script>