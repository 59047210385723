import axios from 'axios'
import 'sweetalert2/dist/sweetalert2.min.css';
const request = axios.create() 

axios.interceptors.request.use(
  (config) => {
      config.baseURL = process.env.VUE_APP_URL_PRODUCCION
      config.headers.service_key = localStorage.getItem('service_key')
      config.headers.async = false
     // Get token and add it to header "Authorization"
     const token = localStorage.getItem('access_token')
     if (token) {
        config.headers.auth_token = token    
     }
     return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(function (response) {
  console.log(response)
  console.log('access_token',response.headers.auth_token)
  if (response.headers.auth_token) {
    localStorage.setItem('access_token', response.headers.auth_token)      
  }
  return response
}, function (error) {
  const { response: { status } } = error
  if (status === 403) {
    window.location.href =  '/login'
    return
  }   
  return Promise.reject(error)
 
})

export default request
