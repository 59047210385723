<script>
  export default {
    methods: {
      $formatNumber (value) {
        if (value == null) {
          return null
        }
        return parseFloat(value).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
