<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="660px"  
    style="z-index:99999"
  >
    <v-card class="grey lighten-2">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row class="justify-center"> 
          <p class="text-h4 colorCustom2--text">¿ Necesita más tiempo ?</p>
        </v-row>
        <v-row class="justify-center">
          <p class="text-h4 colorCustom2--text"> {{ time / 1000 }} </p>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">  
        <botonSi @click="clickSi" class="mx-4"></botonSi>
        <botonNo @ClickNo="clickNo" class="mx-4"></botonNo>      
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import Constants from '../config'
  export default {
    components: {
      botonSi: () => import('./botonSi.vue'),
      botonNo: () => import('./botonNo.vue'),
    },
    data () {
      return {
        time: Constants.time,  
        show: true,
      }
    },  
    created() {
        // this.$swalClose()
        let timerId = setInterval(() => {
          this.time -= 1000          
          console.log(this.time)
          if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)

          if (this.time < 1) {
              clearInterval(timerId)
              // Your logout function should be over here
              this.show = false
              this.$router.push('/')      
            }
        }, 1000);
    },  
    methods: {
      clickSi: function () {
/*         const elem = this.$refs.myBtn
        elem.click() */
      },
      clickNo: function () {
        this.$router.push('/')   
      }
    }
  }
</script>
