import axios from 'axios'

// actions
const actions = {

  getData: async function ({ commit }, payload) {
    commit('loaderOn', null, { root: true })
    let result = null
    console.log(payload.url)
    await axios.get(payload.url)
    .then(response => {
      result = response.data        
    }).catch(error => {
      console.log(error)
      return Promise.reject(error)
    }).finally(() => { 
      commit('loaderOff', null, { root: true })
    })
    return result
  },

  postData: async function ({ commit }, payload) {
    commit('loaderOn', null, { root: true })   
    commit('setDisableBtn', true, { root: true })
    let result = null
    await axios({
      method: 'post',
      url: payload.url,
      data: {
        ...payload.dataForm,
      },
    }).then(response => {
      result = response.data
    }).catch(error => {
      console.log(error)
      return Promise.reject(error)
    }).finally(() => {
      commit('loaderOff',null,  { root: true })     
      commit('setDisableBtn', false, { root: true })
    })
    return result
  },

}

export default {
  namespaced: true,
  actions,  
}
