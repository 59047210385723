<template>
    <div>
        <v-img :src="src" 
            style="width:190px;" 
            @touchstart="active=true" 
            @touchend.stop.prevent="active=false;volver()" 
            @click.stop.prevent="volver()"
            class="mx-auto"> 
        </v-img>     
    </div>
</template>
<script>
export default {
    props: {
       prevent : {
           type : Boolean,
           required : false,
           default : false,
       }
    },
    data () { return {
        active: false,
    }},
    computed: {
        src: function () { 
            return !this.active ? '/img/01volver1.svg' : '/img/01volver2.svg'
        }
    },
    methods: {
        volver : function () {
            if (!this.prevent) {
                this.$router.go(-1)                
            } else {
                this.$emit('backPage')
            }
        }
    }
}
</script>
