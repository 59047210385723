import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import botonBuscar from './components/botonBuscar.vue'
import botonVolver from './components/botonVolver.vue'
import mensajeDialog from './components/mensajeDialog.vue'
import mensajeCerrarDialog from './components/mensajeCerrarDialog.vue'
import mensajeErrorDialog from './components/mensajeErrorDialog.vue'

// import headerVl from './components/headerVl.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import formatNumber from  './mixins/formatNumber'
import formatDate from './mixins/formatDate'
import showError from './mixins/showError'
import generarItemKey from './mixins/generarItemKey'
import IdleVue from 'idle-vue'
import Constants from './config'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import { faSwimmer } from '@fortawesome/free-solid-svg-icons'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faPagelines} from '@fortawesome/free-brands-svg-icons'
library.add(faCar,faHome,faMotorcycle,faSwimmer,faIndustry,faPagelines)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const eventsHub = new Vue();
const options = { eventEmitter: eventsHub,
                  store,
                  idleTime: Constants.idleTime,
                  startAtIdle: false,
                  events: ['keydown','click'],       
               } 
console.log('options', options);

Vue.use(IdleVue, options)
Vue.use(VueSweetalert2);
Vue.mixin(formatNumber)
Vue.mixin(formatDate)
Vue.mixin(showError)
Vue.mixin(generarItemKey)
Vue.config.productionTip = false
Vue.component('botonBuscar', require('./components/botonBuscar.vue').default);
Vue.component('botonVolver', require('./components/botonVolver.vue').default);
Vue.component('mensajeDialog', require('./components/mensajeDialog.vue').default);
// Vue.component('headerVl', require('./components/headerVl.vue').default);
Vue.component('mensajeCerrarDialog', require('./components/mensajeCerrarDialog.vue').default);
Vue.component('mensajeErrorDialog', require('./components/mensajeErrorDialog.vue').default);

Vue.prototype.$EventBus = new Vue()   

new Vue({
  router,
  store,
  vuetify,
  botonBuscar,
  botonVolver,
  mensajeDialog,
  mensajeCerrarDialog,
  mensajeErrorDialog,
  // headerVl,
  render: h => h(App),
}).$mount('#app')
